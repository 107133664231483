export default [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
  },
  {
    header: 'Catalogue',
    icon: 'PackageIcon',
    i18n: 'Catalogue',
    items: [
      {
        url: '/catalogue/categories',
        name: 'Categories',
        slug: 'categories',
        icon: 'BoxIcon',
        i18n: 'Categories'
      },
      {
        url: '/catalogue/brands',
        name: 'Brands',
        slug: 'brands',
        icon: 'TagIcon',
        i18n: 'Brands'
      },
    ]
  },
  {
    header: 'Warehouses',
    icon: 'BoxIcon',
    i18n: 'Warehouse',
    items: [
      {
        url: '/warehouse/warehouses',
        name: 'Warehouses',
        slug: 'warehouses',
        icon: 'BoxIcon',
        i18n: 'Warehouses'
      },
      {
        url: '/warehouse/products',
        name: 'Products',
        slug: 'products',
        icon: 'StarIcon',
        i18n: 'Products'
      },
      {
        url: '/warehouse/inputs',
        name: 'Inputs',
        slug: 'inputs',
        icon: 'PlusIcon',
        i18n: 'Inputs'
      },
      {
        url: '/warehouse/outputs',
        name: 'Outputs',
        slug: 'outputs',
        icon: 'MinusIcon',
        i18n: 'Outputs'
      }
    ]
  },
  {
    header: 'Sales',
    icon: 'ShoppingCartIcon',
    i18n: 'Sales',
    items: [
      {
        url: '/sales/orders',
        name: 'Orders',
        slug: 'orders',
        icon: 'ShoppingCartIcon',
        i18n: 'Orders'
      },
      {
        url: '/sales/clients',
        name: 'Clients',
        slug: 'clients',
        icon: 'UserCheckIcon',
        i18n: 'Clients'
      },
      {
        url: '/sales/coupons',
        name: 'Coupons',
        slug: 'coupons',
        icon: 'ZapIcon',
        i18n: 'Coupons'
      }
    ]
  },
  {
    header: 'Delivery',
    icon: 'TruckIcon',
    i18n: 'Delivery',
    items: [
      {
        url: '/delivery/delivery-areas',
        name: 'Delivery Areas',
        slug: 'delivery-areas',
        icon: 'TargetIcon',
        i18n: 'DeliveryAreas'
      }
    ]
  },
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    i18n: 'Settings',
    items: [
      {
        url: '/settings/general',
        name: 'General',
        slug: 'general',
        icon: 'SettingsIcon',
        i18n: 'General'
      },
      {
        url: '/settings/sliders',
        name: 'Sliders',
        slug: 'sliders',
        icon: 'ImageIcon',
        i18n: 'Sliders'
      },
      {
        url: '/settings/mobile-sliders',
        name: 'MobileSliders',
        slug: 'mobile-sliders',
        icon: 'ImageIcon',
        i18n: 'SlidersMobile'
      },
      {
        url: '/settings/banners',
        name: 'Banners',
        slug: 'banners',
        icon: 'ImageIcon',
        i18n: 'Banners'
      },
      {
        url: '/settings/users',
        name: 'Users',
        slug: 'users',
        icon: 'UserIcon',
        i18n: 'Users'
      },
      {
        url: '/settings/terms',
        name: 'Terms',
        slug: 'terms',
        icon: 'FileIcon',
        i18n: 'Terms'
      },
      {
        url: '/settings/faqs',
        name: 'Faqs',
        slug: 'faqs',
        icon: 'FileIcon',
        i18n: 'Faqs'
      },
      {
        url: '/settings/politics',
        name: 'Politics',
        slug: 'politics',
        icon: 'FileIcon',
        i18n: 'Politics'
      }
    ]
  },

]

